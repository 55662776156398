import $ from 'jquery'

window.WELDING = {
  resetTimeout: function (seconds) {
    let refresh
    const intvrefresh = function () {
      clearInterval(refresh)
      refresh = setTimeout(function () {
        var a = location.href
        location.href = a
      }, seconds * 1000)
    }
    $(document).on('mousemove keypress click scroll', function () { intvrefresh() })
    intvrefresh()
  }
}

window.WELDING_BACK = {
  resetTimeout: function (seconds) {
    let refresh
    const intvrefresh = function () {
      clearInterval(refresh)
      refresh = setTimeout(function () {
        var a = document.referrer
        location.href = a
      }, seconds * 1000)
    }
    $(document).on('mousemove keypress click scroll', function () { intvrefresh() })
    intvrefresh()
  }
}
