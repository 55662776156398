import $ from 'jquery'

const initializeAnimatedNumbers = () => {
  function scrollTrigger (selector, options = {}) {
    let els = document.querySelectorAll(selector)
    els = Array.from(els)
    els.forEach((el) => {
      addObserver(el, options)
    })
  }

  function addObserver (el, options) {
    if (!('IntersectionObserver' in window)) {
      if (options.cb) {
        options.cb(el)
      } else {
        el.target.classList.add('active')
      }
      return
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (options.cb) {
            options.cb(el)
          } else {
            entry.target.classList.add('active')
          }
          observer.unobserve(entry.target)
        }
      })
    }, options)
    observer.observe(el)
  }

  scrollTrigger('.number-count', {
    rootMargin: '-90px',
    cb: function (el) {
      const $this = $(el)
      const countTo = $this.attr('data-count')

      $({ countNum: $this.text() }).animate(
        {
          countNum: countTo
        },

        {
          duration: 1200,
          easing: 'swing',
          step: function () {
            $this.text(Math.floor(this.countNum))
          },
          complete: function () {
            $this.text(this.countNum)
          }
        }
      )
    }
  })
}
export default initializeAnimatedNumbers
