import $ from 'jquery'
const initializeHeader = () => {
  // --- shrink header on scroll ---
  const handleScroll = () => {
    if ($(document).scrollTop() > 100) {
      $('.site-header').addClass('shrink')
    } else {
      $('.site-header').removeClass('shrink')
    }
  }
  $(document).on('scroll', handleScroll)

  // --- toggle mobile menu ---
  const toggleMenu = (event) => {
    event.preventDefault()
    $('.js-menu-toggle, .nav-main').toggleClass('open')
    $('body').toggleClass('menu-open')
  }

  $('.js-menu-toggle').on('click', toggleMenu)

  // --- toggle mobile submenu new ---
  const menuSection = document.querySelector('.nav-main')

  if (menuSection) {
    menuSection.addEventListener('click', (e) => {
      e.target.classList.toggle('open')
    })
  }
}

export default initializeHeader
